<script>
  import { onMount } from "svelte";

  let showCookieModal = false;
  let showCustomizeBox = false;

  let initialConsent = {
    ad_storage: true,
    analytics_storage: true,
    security_storage: true,
  };

  function convertToConsentModeData() {
    return {
      ad_storage: initialConsent.ad_storage ? "granted" : "denied",
      analytics_storage: initialConsent.analytics_storage
        ? "granted"
        : "denied",
      security_storage: initialConsent.security_storage ? "granted" : "denied",
    };
  }

  function updateGtagConsent() {
    gtag("consent", "update", convertToConsentModeData());
  }

  onMount(() => {
    showCookieModal = !localStorage.getItem("consentMode");
  });

  function acceptAllCookies() {
    initialConsent.ad_storage = true;
    initialConsent.analytics_storage = true;
    initialConsent.security_storage = true;
    const consentData = convertToConsentModeData();
    localStorage.setItem("consentMode", JSON.stringify(consentData));
    showCookieModal = false;
    updateGtagConsent();
  }

  function declineCookies() {
    initialConsent.ad_storage = false;
    initialConsent.analytics_storage = false;
    initialConsent.security_storage = false;
    const consentData = convertToConsentModeData();
    localStorage.setItem("consentMode", JSON.stringify(consentData));
    showCookieModal = false;
    updateGtagConsent();
  }

  const savePreferences = () => {
    const consentData = convertToConsentModeData();
    localStorage.setItem("consentMode", JSON.stringify(consentData));
    showCookieModal = false;
    updateGtagConsent();
  };
</script>

<div>
  {#if showCookieModal}
    <div
      class="fixed bottom-0 left-0 right-0 mb-2 mx-auto max-w-xl p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cookie-container lg:max-w-3xl"
    >
      <div class="flex flex-row mb-2">
        <h5
          class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
        >
          Cookies Consent
        </h5>
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white -mt-4 -mr-4"
          on:click={() => (showCookieModal = false)}
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
            <span class="sr-only">Close modal</span>
          </svg>
        </button>
      </div>
      <div class="flex flex-col lg:flex-row">
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 lg:mr-10">
          This website use cookies to help you have a superior and more relavant
          browsing experience on the website
        </p>

        <button
          on:click={() => (showCustomizeBox = true)}
          class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 button lg:w-40 lg:h-12"
          type="button"
        >
          Customize
        </button>

        <button
          on:click={acceptAllCookies}
          type="button"
          id="accept-btn"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button lg:w-52 lg:h-12"
        >
          Accept All
        </button>
      </div>
      {#if showCustomizeBox}
        <div
          class="w-full max-w-xl p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700 lg:max-w-3xl"
        >
          <h5
            class="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white"
          >
            Customize Consent Preferences
          </h5>
          <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
            We use cookies to help you navigate efficiently and perfom certain
            functions.
          </p>
          <ul
            class="my-2 space-y-3 h-80 px-1 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdown-search-button"
          >
            <li>
              <div
                class="p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              >
                <div class="flex items-center">
                  <span class="flex-1 ms-3 whitespace-nowrap">Necessary</span>
                  <span
                    class="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-xs font-medium text-white bg-green-400 rounded dark:bg-green-400 dark:text-white"
                  >
                    always active
                  </span>
                </div>
                <p
                  class="text-sm font-normal text-gray-500 dark:text-gray-400 pl-2 pt-5"
                >
                  Necessary cookies are required to enable the basic features of
                  the site.
                </p>
              </div>
            </li>
            <li>
              <div
                class="p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              >
                <div class="flex items-center">
                  <label
                    class="inline-flex items-center cursor-pointer lg:space-x-48"
                  >
                    <span class="flex-1 ms-3 whitespace-nowrap mr-80">
                      Analytics
                    </span>
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      bind:checked={initialConsent.analytics_storage}
                    />
                    <div
                      class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    ></div>
                  </label>
                </div>
                <p
                  class="text-sm font-normal text-gray-500 dark:text-gray-400 pl-2 pt-5"
                >
                  Analytics cookies help us understand how visitors interact
                  with the our website and improve the user experience.
                </p>
              </div>
            </li>
            <li>
              <div
                class="p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              >
                <div class="flex items-center">
                  <label
                    class="inline-flex items-center cursor-pointer lg:space-x-48"
                  >
                    <span class="flex-1 ms-1 whitespace-nowrap mr-72">
                      Advertisement
                    </span>
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      bind:checked={initialConsent.ad_storage}
                    />
                    <div
                      class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    ></div>
                  </label>
                </div>
                <p
                  class="text-sm font-normal text-gray-500 dark:text-gray-400 pl-2 pt-5"
                >
                  Advertisement cookies are used to provide visitors with
                  relevant ads
                </p>
              </div>
            </li>
          </ul>
          <button
            on:click={savePreferences}
            type="button"
            id="preference-btn"
            class="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-800 button w-full"
          >
            Save My Preferences
          </button>
        </div>
      {/if}
    </div>
  {/if}
</div>
